import React, { useState, useEffect, useRef } from "react";
import { useInput } from '../../hooks/useInput';
import getDepartmentsList from "../../api/services/userDepartmentsList";
import getDepartmentEntitiesList from "../../api/services/departmentsEntitiesList";
import craeteCase from "../../api/services/createCase";
import { MultiSelect } from "react-multi-select-component";
import { HalfCircleSpinner } from 'react-epic-spinners';
import { toast } from 'react-toastify';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from "../../api/services/auth";
import ShowPreview from "../../components/upload-case/showPreview";
import ImageUpload from "../../components/upload-case/imageUpload";
import { userPermissions } from "../../enums";
import DatePicker from "react-date-picker";
import { groupConsultant } from '../../api/services/departmnent-services/index';
import Popup from "reactjs-popup";
import { requestSuppourt } from "../../api/services/support-request";

const UploadCase = (props) => {
    const { userExist, userRole, permission } = useAuth()
    const [caseRef, bindCaseRef, resetCaseRef] = useInput("");
    const [gender, setGender] = useState(null);
    const [age, setAge] = useState("");
    const [departmentList, setDepartmentList] = useState([]);
    const [departmentEntitiesList, setDepartmentEntitiesList] = useState([]);
    const [procedure, setProcedure] = useState('');
    const [currentUser, setCurrentUser] = useState(localStorage.getItem("loggedInUser"));
    const [consultantList, setConsultantList] = useState([]);
    const [consultant, setConsultant] = useState('');
    const [department, setDepartment] = useState(null);
    const [group, setGroup] = useState('');
    const [date, setDate] = useState('')
    // const [time, bindTime, resetTime] = useInput("");
    const [time, setTime] = useState("");
    const [medicalHistory, bindMedicalHistory, resetMedicalHistory] = useInput("");
    const [comorbidityListing, setComorbidityListing] = useState([]);
    const [comorbidities, setComorbidities] = useState([]);
    const [selectedComorbidities, setSelectedComorbidities] = useState([]);
    const [complicationListing, setComplicationListing] = useState([]);
    const [complications, setComplications] = useState([]);
    const [selectedComplications, setSelectedComplications] = useState([]);
    const [notes, bindNotes, resetNotes] = useInput("");
    const [errors, setErrors] = useState({});
    const [showLoaderDraft, setShowLoaderDraft] = useState(false);
    const [showLoaderUpcoming, setShowLoaderUpcoming] = useState(false);
    const [showLoaderUpdate, setShowLoaderUpdate] = useState(false);
    const [draftButtonDisabled, setDraftButtonDisabled] = useState(false);
    const [upcomingButtonDisabled, setUpcomingButtonDisabled] = useState(false);
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [imagesToUpload, setImagesToUpload] = useState([]);
    const [editedImageIndex, setEditedImageIndex] = useState(null);
    const [toggleStates, setToggleStates] = useState([]);
    const [supportRequestModal, setSupportRequstModal] = useState('');
    const [supportRequestContent, setSupportRequestContent] = useState('');
    const [showLoader, setShowLoader] = useState(false);

    const navigate = useNavigate();
    const myRef = useRef(null);

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Upload Case | Cappagh";
    }, []);

    // Error_Scroll_Reference
    const scrollToTopRef = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    // Handle_Scroll_Reference_To_Top
    const executeScroll = () => {
        scrollToTopRef(myRef);
    };

    useEffect(() => {
        if (Object.keys(errors)?.length > 0) {
            executeScroll();
            setShowPreview(false)
        }
    }, [errors]);

    // Handle_Gender_Select
    const handleGenderSelect = (event) => {
        setGender(event.target.value);
    };

    // Fetch_Department_List_Based_On_Current_User
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        const loggedInUser = localStorage.getItem('loggedInUser');

        if (token) {
            const parsedUser = JSON.parse(loggedInUser);
            setCurrentUser(parsedUser);

            if (parsedUser.id) {
                getDepartmentsList.get(parsedUser.id)
                    .then((res) => {
                        setDepartmentList(res?.data);

                        if (res?.data?.length === 1) {
                            setDepartment(res.data[0].id)
                            fetchDepartmentEntitiesList(res.data[0].id);
                        }
                    }).catch(error => {
                        console.error('Error fetching data:', error);
                    }
                    );
            }
        }
    }, []);

    // Handle_Department_Select
    const handleDepartmentSelect = (event) => {
        const selectedDepartmentId = event.target.value;

        setDepartment(selectedDepartmentId);
        setConsultant('')
        setProcedure('')
        setGroup('')
        setSelectedComplications([])
        setComplicationListing([]);
        setComorbidityListing([]);
        setSelectedComorbidities([])

        if (event.target.value) {
            fetchDepartmentEntitiesList(event.target.value);
        }
    };

    // Fetch_Selected_Department_Entities_Listing
    const fetchDepartmentEntitiesList = (id) => {
        getDepartmentEntitiesList.get(id)
            .then((res) => {
                setDepartmentEntitiesList(res.data);

                if (res.data.comorbidities.length) {
                    setComorbidityListing(res.data.comorbidities);
                }

                if (res.data.complications.length) {
                    setComplicationListing(res.data.complications);
                }

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            }
            );
    }

    const transformedComorbidityData = comorbidityListing.map(item => ({
        value: item.id,
        label: item.name,
    }));

    const transformedComplicationData = complicationListing.map(item => ({
        value: item.id,
        label: item.name,
    }));

    // Handle_Procedure_Select
    const handleProcedureSelect = (event) => {
        setProcedure(event.target.value);
    };

    // Handle_Consultant_Select
    const handleConsultantSelect = (event) => {
        setConsultant(event.target.value);
    }

    // Handle_Group_Select
    const handleGroupSelect = (event) => {
        setGroup(event.target.value);
        setConsultant('')
        setConsultantList([])
        if (event.target.value) {
            fetchGroupEntities(event.target.value);
        }
    };

    // Fetch_Selected_Group_Consultant_List
    const fetchGroupEntities = (id) => {
        groupConsultant.get(id)
            .then((res) => {
                setConsultantList(res.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            }
            );
    }

    const transformedGroupData = consultantList.map(item => ({
        value: item.id,
        label: item.full_name,
    }));

    // Handle_Tag_Removal_(Comorbidity)
    const handleComorbidityTagRemove = (tagId) => {
        const updatedSelectedComorbidities = selectedComorbidities.filter((tag) => tag.value !== tagId);
        setSelectedComorbidities(updatedSelectedComorbidities);
        const updatedComorbidities = comorbidities.filter((tag) => tag.value !== tagId);
        setComorbidities(updatedComorbidities);
    };

    // Handle_Tag_Removal_(Complication)
    const handleComplicationTagRemove = (tagId) => {
        const updatedSelectedComplication = selectedComplications.filter((tag) => tag.value !== tagId);
        setSelectedComplications(updatedSelectedComplication);
        const updatedComplications = complications.filter((tag) => tag.value !== tagId);
        setComplications(updatedComplications);
    };

    // Check_Validation_Function
    const checkValidation = () => {
        let errors = {};
        let isValid = true;
        if (!caseRef) {
            isValid = false;
            errors['caseRef'] = 'Please enter a "unique" Case Ref.';
        }
        if (!gender) {
            isValid = false;
            errors['gender'] = 'Please select a Gender.';
        }
        if (!age) {
            isValid = false;
            errors['age'] = 'Please select Age.';
        }
        if (!procedure) {
            isValid = false;
            errors['procedure'] = 'Please select a Procedure.';
        }
        if (!consultant) {
            isValid = false;
            errors['consultant'] = 'Please select a Consultant.';
        }
        if (!department) {
            isValid = false;
            errors['department'] = 'Please select a Department.';
        }
        if (!group) {
            isValid = false;
            errors['group'] = 'Please select a Group.';
        }
        if (date) {
            if (date.split('-')[0].length > 4) {
                isValid = false;
                errors['date'] = 'Please enter a valid date.';
                toast.error('Please enter a valid date.');
            }
        }
        setErrors(errors);
        executeScroll();
        return isValid;
    }

    // Handle_Create_Case
    const handleCreateCase = (event, caseStatus, setShowLoader, setButtonDisabled) => {
        event.preventDefault();
        if (checkValidation()) {
            setButtonDisabled(true);
            setShowLoader(true);

            const requestBody = new FormData();
            requestBody.append('reference', caseRef);
            requestBody.append('gender', gender);
            requestBody.append('age', age);
            requestBody.append('procedure_id', procedure);
            requestBody.append('consultant_id', consultant);
            requestBody.append('department_id', department);
            requestBody.append('group_id', group);
            requestBody.append('status', caseStatus);
            date && requestBody.append('procedure_date', date);
            time && requestBody.append('operative_time', time);
            medicalHistory && requestBody.append('past_medical_history', medicalHistory);
            notes && requestBody.append('note', notes);
            (selectedComorbidities && (selectedComorbidities?.length > 0)) && selectedComorbidities.forEach((comorbidity, index) => {
                requestBody.append(`comorbidity_ids[${index}]`, comorbidity.value);
            });
            (selectedComplications && (selectedComplications?.length > 0)) && selectedComplications.forEach((complication, index) => {
                requestBody.append(`complication_ids[${index}]`, complication.value);
            });
            (imagesToUpload && (imagesToUpload?.length > 0)) && imagesToUpload.forEach((img, index) => {
                requestBody.append(`images[${index}]`, img.file);
            });
            (toggleStates && (toggleStates?.length > 0)) && toggleStates.forEach((img, index) => {
                requestBody.append(`image_type[${index}]`, img ? 1 : 0);
            });
            craeteCase.create((requestBody))
                .then((res) => {
                    if (res.status === 200) {
                        setShowLoader(false);
                        toast.success(res.message);
                        window.history.back();
                    } else {
                        toast.error(res.data.message);
                    }
                }
                ).catch((error) => {
                    setShowLoader(false);
                    if (error?.data?.errors?.id) {
                        toast.error('Case With same Refrence is in archive. Contact administrator to restore it');
                    } else {
                        toast.error(error.data.message);
                    }
                }
                ).finally(() => {
                    setShowLoader(false);
                    setButtonDisabled(false);
                }
                );
        }
    }

    //handle_case_preview
    const handlePreviewCase = () => {
        setShowPreview(!showPreview)
    }

    //function_selection_Complication
    const handleSelectedComplication = (options) => {
        setSelectedComplications(options)
    }

    //function_selection_Comorbidity
    const handleSelectedComorbidity = (options) => {
        setSelectedComorbidities(options)
    }

    //function_selection_Comorbidity
    const handleSupportRequest = (e, option) => {
        e.preventDefault();
        setSupportRequstModal(option)
    }

    // date_slecetion_date
    const handleDateChange = (newDate) => {
        if (newDate instanceof Date && !isNaN(newDate)) {
            const year = newDate.getFullYear();
            const month = String(newDate.getMonth() + 1).padStart(2, '0');
            const day = String(newDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            setDate(formattedDate);
        } else {
            setDate(null);
        }
    };
    const checkCreate = () => {
        let errors = {};
        let isValid = true;
        if (!supportRequestContent) {
            isValid = false;
            errors['supportRequestContent'] = 'Please enter a "unique" Case Ref.';
        }

        setErrors(errors)
        return isValid;
    }

    const handleRequest = () => {
        if (checkCreate()) {
            setShowLoader(true)
            requestSuppourt.request({ heading: `Request additional ${supportRequestModal}`, content: supportRequestContent }).then((res) => {
                toast.success(res.message);
                setSupportRequstModal('')
                setSupportRequestContent('')
                setShowLoader(false)
            }).catch((error) => {
                toast.error(error.data.message);
                setShowLoader(false)
            })
        }
    }

    //chcek auth
    if (!userExist) {
        return <Navigate to="/login" />
    }

    return (
        <div ref={myRef} className="dbContentWrap caseDetail">
            {showPreview && <ShowPreview
                caseData={{
                    comorbidities: selectedComorbidities?.map(obj => obj.label),
                    complications: selectedComplications?.map(obj => obj.label),
                    consultant: transformedGroupData?.find(p => p?.value == consultant)?.label ?? null,
                    date: date,
                    gender: gender,
                    group: departmentEntitiesList?.groups?.find(p => p?.id == group)?.name ?? null,
                    note: null,
                    operative_time: time,
                    past_medical_history: medicalHistory,
                    procedure: departmentEntitiesList?.procedures?.find(p => p?.id == procedure)?.name ?? null,
                    note: notes,
                    reference: caseRef,
                    age: age,
                    images: imagesToUpload
                }}
            />}

            <form className="uploadCase createCase">
                <div className="dbRow">
                    {!showPreview && (
                        <>
                            <div className="dbCol dbColLg35">
                                <article className="widgetWrap detailWidget">
                                    <div className="widgetHeader">
                                        <span>Case Details</span>
                                    </div>
                                    <ul className="listUnstyled widgetDetailList listStriped roundedBottom">
                                        <li>
                                            <label className="txTitle">Case Ref<span className="required">*</span></label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.caseRef && 'error'}`}
                                                    placeholder="Case Ref (Required)"
                                                    {...bindCaseRef}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <label className="txTitle">Gender<span className="required">*</span></label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="gender"
                                                    className={`formControl ${errors.gender && 'error'}`}
                                                    id="gender"
                                                    value={gender}
                                                    onChange={handleGenderSelect}
                                                >
                                                    <option selected disabled>Select (Required)</option>
                                                    <option id="1" value="male">Male</option>
                                                    <option id="2" value="female">Female</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <label className="txTitle">Age<span className="required">*</span></label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.age && 'error'}`}
                                                    placeholder="Age (Required)"
                                                    value={age}
                                                    onChange={(e) => {
                                                        const inputNumber = e.target.value;
                                                        if (/^[0-9]*$/.test(inputNumber)) {
                                                            setAge(inputNumber)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </li>
                                        {departmentList.length > 1 &&
                                            <li>
                                                <label className="txTitle">Department<span className="required">*</span></label>
                                                <div className="selectWrap posRelative">
                                                    <select
                                                        name="department"
                                                        className={`formControl ${errors.department && 'error'}`}
                                                        id="department"
                                                        value={department}
                                                        onChange={handleDepartmentSelect}
                                                    >
                                                        {departmentList && departmentList.length > 0 && (
                                                            <>
                                                                <option value="" >Select Department</option>
                                                                {departmentList.map((item) => (
                                                                    <option key={item.id} value={item.id} selected={departmentList?.length === 1}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        )}
                                                    </select>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <label className="txTitle">Group<span className="required">*</span></label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="group"
                                                    className={`formControl ${errors.group && 'error'}`}
                                                    id="group"
                                                    value={group}
                                                    onChange={handleGroupSelect}
                                                    disabled={!department}
                                                >
                                                    <option value='' >Select (Required)</option>
                                                    {(departmentEntitiesList?.groups && (departmentEntitiesList?.groups?.length > 0)) && departmentEntitiesList?.groups.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <label className="txTitle">Consultant<span className="required">*</span></label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="consultant"
                                                    className={`formControl ${errors.consultant && 'error'}`}
                                                    id="consultant"
                                                    value={consultant}
                                                    onChange={handleConsultantSelect}
                                                    disabled={!department || !group}
                                                >
                                                    <option value=''>Select Consultant</option>
                                                    {transformedGroupData.map((item) => (
                                                        <option key={item.value} value={item.value}>
                                                            <span classNames="txtCaptilize">{item.label}</span>
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <label className="txTitle">Procedure<span className="required">*</span></label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="procedure"
                                                    className={`formControl ${errors.procedure && 'error'}`}
                                                    id="procedure"
                                                    value={procedure}
                                                    onChange={handleProcedureSelect}
                                                    disabled={!department}
                                                >
                                                    <option value="">Select (Required)</option>
                                                    {(departmentEntitiesList?.procedures && (departmentEntitiesList?.procedures?.length > 0)) && departmentEntitiesList?.procedures.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <label className="txTitle">Procedure Date</label>
                                            <div className="inputWrap dateWrap">
                                                <DatePicker
                                                    className="formControl datePickerInput"
                                                    onChange={handleDateChange}
                                                    value={date ? new Date(date) : null}
                                                    dayPlaceholder='dd'
                                                    monthPlaceholder='mm'
                                                    yearPlaceholder='yyyy'
                                                    format='y/MM/dd'
                                                    maxDate={new Date(2099, 11, 31)} // Set maximum date to December 31, 2099
                                                    clearIcon={date ? <i className="fa-regular fa-xmark"></i> : null}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <label className="txTitle">Operative Time</label>
                                            <div className="inputWrap timeWrap">
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    value={time}
                                                    onChange={(e) => {
                                                        const inputNumber = e.target.value;
                                                        if (/^[0-9]*$/.test(inputNumber)) {
                                                            setTime(inputNumber);
                                                        }
                                                    }}
                                                />Hrs
                                            </div>
                                        </li>
                                    </ul>
                                </article>
                            </div>
                            <div className="dbCol dbColLg65">
                                <div className="dbRow hisNotRow">
                                    <div className="dbCol dbColLg50">
                                        <article className="widgetWrap historyWidget">
                                            <div className="widgetHeader">
                                                <span>Past Medical History</span>
                                            </div>
                                            <div className="widgetBody">
                                                <textarea
                                                    className="formControl"
                                                    {...bindMedicalHistory}
                                                >
                                                </textarea>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <article className="widgetWrap historyWidget noteWidget">
                                            <div className="widgetHeader">
                                                <span>Notes</span>
                                            </div>
                                            <div className="widgetBody">
                                                <textarea
                                                    className="formControl"
                                                    {...bindNotes}
                                                >
                                                </textarea>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <article className="widgetWrap comoWidget">
                                    <div className="widgetHeader">
                                        <span>Comorbidities </span>
                                        {permission?.some(p => (p.name === userPermissions.CONTACT_REQUEST_STORE)) && (
                                            <div className="tabHeadActionWrap">
                                                <button type="button"
                                                    className={`actionBtn`} onClick={(e) => handleSupportRequest(e, 'Comorbidity')}>Request additional Comorbidity <i className="fa-solid fa-user-plus"></i></button>
                                            </div>
                                        )}
                                    </div>
                                    <div className="widgetBody">
                                        <div className="multiSelectWrap">
                                            <label>Search Tags</label>
                                            <MultiSelect
                                                options={transformedComorbidityData}
                                                value={selectedComorbidities}
                                                onChange={handleSelectedComorbidity}
                                                labelledBy="Select"
                                                className="multi-tag-select w100"
                                                hasSelectAll={false}
                                                disabled={!department}
                                            />
                                        </div>
                                        <ul className="listUnstyled tagList multiTagList">
                                            {(selectedComorbidities && (selectedComorbidities?.length > 0)) && selectedComorbidities.map((tag) => (
                                                <li key={tag.value}>
                                                    {tag.label}
                                                    <span className="tagRemove" onClick={() => handleComorbidityTagRemove(tag.value)}>×</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </article>
                                <article className="widgetWrap compWidget">
                                    <div className="widgetHeader">
                                        <span>Complications </span>
                                        {permission?.some(p => (p.name === userPermissions.CONTACT_REQUEST_STORE)) && (
                                            <div className="tabHeadActionWrap">
                                                <button type="button"
                                                    className={`actionBtn`} onClick={(e) => handleSupportRequest(e, 'Complication')}>Request additional Complication <i className="fa-solid fa-user-plus"></i></button>
                                            </div>
                                        )}
                                    </div>
                                    <div className="widgetBody">
                                        <div className="multiSelectWrap">
                                            <label>Search Tags</label>
                                            <MultiSelect
                                                options={transformedComplicationData}
                                                value={selectedComplications}
                                                onChange={handleSelectedComplication}
                                                labelledBy="Select"
                                                className="multi-tag-select w100"
                                                hasSelectAll={false}
                                                disabled={!department}
                                            />
                                        </div>
                                        <ul className="listUnstyled tagList multiTagList">
                                            {(selectedComplications && (selectedComplications?.length > 0)) && selectedComplications.map((tag) => (
                                                <li key={tag.value}>
                                                    {tag.label}
                                                    <span className="tagRemove" onClick={() => handleComplicationTagRemove(tag.value)}>×</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </article>
                            </div>
                        </>
                    )}
                    <div className="dbCol">
                        <div style={{ display: showPreview ? 'none' : 'block' }}>
                            <ImageUpload
                                setImagesToUpload={setImagesToUpload}
                                setEditedImageIndex={setEditedImageIndex}
                                editedImageIndex={editedImageIndex}
                                toggleStates={toggleStates}
                                setToggleStates={setToggleStates}
                            />
                        </div>
                        <div className="actionsBtnWrap">
                            <button onClick={handlePreviewCase} type="button" className="btn btnPrimary"> {showPreview ? 'Back To Edit' : 'Preview Case'}
                            </button>
                            <button
                                type="button"
                                className="btn btnSecondary"
                                disabled={(editedImageIndex !== null) || draftButtonDisabled || showLoaderDraft}
                                onClick={(event) => handleCreateCase(event, 'draft', setShowLoaderDraft, setDraftButtonDisabled)}
                            >Save as Draft
                                {showLoaderDraft && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                            <button
                                type="submit"
                                className="btn btnSecondary"
                                disabled={(editedImageIndex !== null) || updateButtonDisabled || showLoaderUpdate}
                                onClick={(event) => handleCreateCase(event, 'published', setShowLoaderUpdate, setUpdateButtonDisabled)}
                            >Publish Case
                                {showLoaderUpdate && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </form>

            <Popup
                className="deleteUserModal supportModal"
                open={supportRequestModal !== ''}
                onClose={() => {
                    setSupportRequstModal('')
                }}
            >
                {supportRequestModal !== '' && (
                    <div className="">
                        <form className="">
                            <article className="widgetWrap notifWidget sendNotiBox">
                                <div className="widgetHeader">
                                    <span>Request additional {supportRequestModal}</span>
                                </div>
                                <div className="widgetFooter">
                                    <div className="notifBody">
                                        <textarea
                                            className={`formControl ${errors.supportRequestContent && "error"}`}
                                            name=""
                                            id=""
                                            onChange={(e) => setSupportRequestContent(e.target.value)}
                                        ></textarea>
                                    </div>
                                    <div className="notifActions">
                                        <button
                                            onClick={handleRequest}
                                            type="button"
                                            className="btn btnSecondary w100 dBlock"
                                            disabled={showLoader}
                                        >Request  {showLoader && (
                                            <HalfCircleSpinner
                                                size={18}
                                                style={{
                                                    display: "inline-block",
                                                    marginLeft: "7px",
                                                    position: 'relative',
                                                    top: '2px',
                                                }}
                                            />
                                        )}</button>
                                    </div>
                                </div>
                            </article>
                        </form>
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default UploadCase;
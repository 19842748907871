import React, { useEffect } from "react";
import { useAuth } from "../../api/services/auth";
import { Navigate } from "react-router-dom";
import AssistantDashboard from "../../components/dashboard/assistantDashboard";
import HodDashboard from "../../components/dashboard/hodDashbaord";
import { userRoles } from "../../enums";
import ConsultantDashboard from "../../components/dashboard/consultantDashbaord";

const Dashboard = (props) => {
    const { userExist, userRole, user } = useAuth()

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Dashboard | Cappagh";
    }, []);

    if (!userExist) {
        return <Navigate to="/login" />
    }

    return (
        <>
            {
                (user.type === userRoles.SUPER_USER) ?
                    <HodDashboard /> :
                    (user.type === userRoles.AUDITOR) ?
                        <HodDashboard /> :
                        (user.type === userRoles.ASSISTANT) ?
                            <AssistantDashboard /> :
                            (user.type === userRoles.HOD) ?
                                <HodDashboard /> :
                                (user.type === userRoles.CONSULTANT) ?
                                    <ConsultantDashboard /> :
                                    <div className="dbContentWrap">{userRole}</div>
            }
        </>
    )
}

export default Dashboard;

import React, { useState, useEffect } from 'react';
import { dashboardStats } from '../../api/services/dashboard-stats/index'
import { PieChart } from 'react-minimal-pie-chart';
import Popup from 'reactjs-popup';
import DatePicker from 'react-date-picker';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { useAuth } from '../../api/services/auth';
import { userPermissions } from '../../enums';

function ConsultantStats() {
    const [statsData, setStatsData] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [filterType, setFilterType] = useState('this-week')
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [errors, setErrors] = useState({});
    const { permission } = useAuth();

    // Get_Dashboard_Stats_Data
    useEffect(() => {
        dashboardStats.get('this-week')
            .then((res) => {
                setShowLoader(false);
                setStatsData(res?.data);
            }
            ).catch(error => {
                setShowLoader(false);
                console.error('Error fetching data:', error);
            }
            );
    }, []);

    const checkValidation = () => {
        let errors = {};
        let isValid = true;

        if (!fromDate) {
            isValid = false;
            errors['fromDate'] = 'Please enter from Date.';
        }
        if (!toDate) {
            isValid = false;
            errors['toDate'] = 'Please select to Date.';
        }
        setErrors(errors);
        return isValid;
    };

    const handleFilter = (e) => {
        const filter = e.target.value;
        setFilterType(filter)
        if (filter !== 'custom') {
            setShowLoader(true);

            dashboardStats.get(filter)
                .then((res) => {
                    setShowLoader(false);
                    setStatsData(res?.data);
                }
                ).catch(error => {
                    setShowLoader(false);
                    console.error('Error fetching data:', error);
                }
                );
        } else if (filter === 'custom') {
            setShowDatePicker(true)
            e.target.value = null
        }
    }

    const handleCustomSubmit = () => {
        if (checkValidation()) {
            setShowDatePicker(false)
            // setFromDate('')
            // setToDate('')
        }
    }

    const handleFromDate = (newDate) => {
        if (newDate instanceof Date && !isNaN(newDate)) {
            const year = newDate.getFullYear();
            const month = String(newDate.getMonth() + 1).padStart(2, '0');
            const day = String(newDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            setFromDate(formattedDate);
        } else {
            setFromDate(null);
        }
    };

    const handleToDate = (newDate) => {
        if (newDate instanceof Date && !isNaN(newDate)) {
            const year = newDate.getFullYear();
            const month = String(newDate.getMonth() + 1).padStart(2, '0');
            const day = String(newDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            setToDate(formattedDate);
        } else {
            setToDate(null);
        }
    };

    return (
        <>
            <div className="statsWidget statsChartWidget consultantStats colSpan100" style={{ minHeight: '355px' }}>
                <article className="widgetWrap overflowHidden" style={{ minHeight: 'inherit' }}>
                    <div className="widgetHeader">
                        <span>All Department Stats</span>
                        <div className="selectWrap posRelative">
                            <select onChange={handleFilter} name="cases" className="widgetSelect" id="all-cases">
                                <option value="this-week">This Week</option>
                                <option value="this-month">This Month</option>
                                <option value="last-week">Last Week</option>
                                <option value="last-month">Last Month</option>
                                {/* <option value="custom">Custom</option> */}
                            </select>
                        </div>
                    </div>
                    {showLoader ? (
                        <div className='textCenter loadingState' style={{ minHeight: 'inherit' }}>
                            <HalfCircleSpinner
                                size={18}
                                color='black'
                                style={{
                                    display: "inline-block",
                                    marginLeft: "7px",
                                    position: "relative",
                                    top: "2px",
                                }}
                            />
                        </div>
                    ) : (
                        <div className='widgetBody'>
                            <div className='stwRow'>
                                {permission?.some((p) => p.name === userPermissions.CASES_LIST) && (
                                    <>
                                        <div className="stwCol colMd28">
                                            <article className="widgetWrap w100">
                                                <div className="widgetHeader">
                                                    <span>All Cases</span>
                                                </div>
                                                <ul className="listUnstyled widgetListing listStriped">
                                                    <li><span className="countNumb">{statsData[0]?.un_reviewed}</span>Unreviewed Cases</li>
                                                    <li><span className="countNumb">{statsData[0]?.reviewed}</span>Reviewed Cases</li>
                                                    <li><span className="countNumb">{statsData[0]?.cases_for_discussion}</span>Discussed Cases</li>
                                                </ul>
                                            </article>
                                        </div>

                                        <div className="stwCol colMd28">
                                            <article className="widgetWrap w100">
                                                <div className="widgetHeader">
                                                    <span>My Cases</span>
                                                </div>
                                                <ul className="listUnstyled widgetListing listStriped">
                                                    <li><span className="countNumb">{statsData[0]?.my_unreviewed}</span>Unreviewed Cases</li>
                                                    <li><span className="countNumb">{statsData[0]?.my_reviewed}</span>Reviewed Cases</li>
                                                    <li><span className="countNumb">{statsData[0]?.my_comments_count}</span>With Comments</li>
                                                </ul>
                                            </article>
                                        </div>
                                    </>)}
                                {permission?.some((p) => p.name === userPermissions.USERS_LIST) && (
                                    <div className='stwCol colMd28 colLg30'>
                                        <article className="widgetWrap w100">
                                            <div className="widgetHeader">
                                                <span>Activity within Group</span>
                                            </div>
                                            <div className='statsChart'>
                                                {statsData.length > 0 &&
                                                    <div className='statsPieChart posRelative'>
                                                        {((statsData[0]?.active_users !== 0) || (statsData[0]?.in_active_users !== 0)) &&
                                                            <div className='statsCenter'>
                                                                <span className='number'>{`${Math.floor(statsData[0]?.active_users * 100 / (statsData[0]?.in_active_users))}%`}</span>
                                                                <span className='label'>Active Users</span>
                                                            </div>
                                                        }
                                                        <PieChart
                                                            className='svgPieChart'
                                                            data={[
                                                                { title: `${statsData[0]?.in_active_users} Total Users`, value: statsData[0]?.in_active_users - statsData[0]?.active_users, color: 'var(--primary-color)' },
                                                                { title: `${statsData[0]?.active_users} Active Users`, value: statsData[0]?.active_users, color: 'var(--secondary-color)' },
                                                            ]}
                                                        />
                                                    </div>
                                                }
                                                <div className='statsNumber txtRight'>
                                                    <ul className='statsColorList listUnstyled'>
                                                        <li><div className='stColor'><span style={{ backgroundColor: 'var(--primary-color)' }}></span> Total Users</div></li>
                                                        <li><div className='stColor'><span style={{ backgroundColor: 'var(--secondary-color)' }}></span> Active Users</div></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                )}
                                {permission?.some((p) => p.name === userPermissions.CASES_LIST) && (
                                    <div className='stwCol colMd28 colLg30'>
                                        <article className="widgetWrap w100">
                                            <div className="widgetHeader">
                                                <span>Activity within Group</span>
                                            </div>
                                            <div className='statsChart'>
                                                {statsData.length > 0 &&
                                                    <div className='statsPieChart posRelative'>
                                                        {((statsData[0]?.reviewed !== 0) || statsData[0]?.un_reviewed !== 0) &&
                                                            <div className='statsCenter'>
                                                                <span className='number'>{statsData[0]?.reviewed}</span>
                                                                <span className='label'>Cases Reviewed</span>
                                                            </div>
                                                        }
                                                        <PieChart
                                                            className='svgPieChart'
                                                            data={[
                                                                { title: `${statsData[0]?.un_reviewed} Unreviewed Cases`, value: (statsData[0]?.un_reviewed + statsData[0]?.reviewed) - statsData[0]?.reviewed, color: 'var(--primary-color)' },
                                                                { title: `${statsData[0]?.reviewed} Reviewed Cases`, value: statsData[0]?.reviewed, color: 'var(--secondary-color)' },
                                                            ]}
                                                        />
                                                    </div>
                                                }
                                                <div className='statsNumber txtRight'>
                                                    <div className='stNumber caseGoal'>{statsData[0]?.goal} Case Goal</div>
                                                    {/* <ul className='statsList listUnstyled'>
                                                    <li><div className='stNumber'></div></li>
                                                    <li><div className='stNumber'></div></li>
                                                </ul> */}
                                                    <ul className='statsColorList listUnstyled'>
                                                        <li><div className='stColor'><span style={{ backgroundColor: 'var(--primary-color)' }}></span> {statsData[0]?.un_reviewed} Unreviewed</div></li>
                                                        <li><div className='stColor'><span style={{ backgroundColor: 'var(--secondary-color)' }}></span> {statsData[0]?.reviewed} Reviewed</div></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </article>
            </div>

            <Popup
                className="deleteUserModal"
                open={showDatePicker}
                onClose={() => {
                    setShowDatePicker(false)
                }}
            >
                {showDatePicker && (
                    <div className="modal textCenter">
                        <div>
                            <p>From</p>
                            <DatePicker
                                className={`formControl datePickerInput ${errors.fromDate && 'error'}`}
                                onChange={handleFromDate}
                                value={fromDate ? new Date(fromDate) : null}
                                dayPlaceholder='dd'
                                monthPlaceholder='mm'
                                yearPlaceholder='yyyy'
                                format='y/MM/dd'
                                maxDate={new Date(2099, 11, 31)} // Set maximum date to December 31, 2099
                            />
                        </div>
                        <div>
                            <p>To</p>
                            <DatePicker
                                className={`formControl datePickerInput ${errors.toDate && 'error'}`}
                                onChange={handleToDate}
                                value={toDate ? new Date(toDate) : null}
                                dayPlaceholder='dd'
                                monthPlaceholder='mm'
                                yearPlaceholder='yyyy'
                                format='y/MM/dd'
                                maxDate={new Date(2099, 11, 31)} // Set maximum date to December 31, 2099
                            />
                        </div>
                        <div className="btnWrap">
                            <button
                                onClick={(e) => {
                                    setShowDatePicker(false)
                                    setFromDate('')
                                    setToDate('')
                                }}
                                type="button"
                                className="btn btnSecondary"
                            >Cancel</button>
                            <button
                                onClick={(e) => {
                                    handleCustomSubmit(e)
                                }}
                                type="button"
                                className="btn btnPrimary"
                            >Submit </button>
                        </div>
                    </div>
                )}
            </Popup>
        </>
    )
}

export default ConsultantStats
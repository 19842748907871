import React from "react";
import { Link, useLocation } from "react-router-dom";
import { logout } from "../../../api/services/logoutUser";
import { userPermissions } from "../../../enums";
import { useAuth } from "../../../api/services/auth";

const AssistantNav = () => {
    const location = useLocation();
    const { permission } = useAuth();

    //check which route is acive for active sidebar color
    const isRouteActive = (route) => {
        return location.pathname === route;
    };

    // all routes array along with permission and roles
    const routes = [
        { path: "/dashboard", label: "Dashboard" },
         ...((permission?.some(p => (p.name === userPermissions.CASES_LIST)))
                    ? [{ path: "/cases-directory", label: "Case Directory" }]
                    : []
                ),
        ...((permission?.some(p => (p.name === userPermissions.CASES_LIST)))
            ? [{ path: "/draft-cases", label: "Draft Cases" }]
            : []
        ),
        ...((permission?.some(p => (p.name === userPermissions.CASES_LIST)))
            ? [{ path: "/published-cases", label: "Published Cases" }]
            : []
        ),
        ...((permission?.some(p => (p.name === userPermissions.CASES_LIST)))
            ? [{ path: "/upcoming-cases", label: "Scheduled Cases" }]
            : []
        ),
        ...((permission?.some(p => (p.name === userPermissions.CASES_LIST)))
            ? [{ path: "/pending-review", label: "Pending Review" }]
            : []
        ),
        ...((permission?.some(p => (p.name === userPermissions.CASES_LIST)))
            ? [{ path: "/discuss-cases", label: "For Discussion" }]
            : []
        ),
        ...((permission?.some(p => (p.name === userPermissions.CASES_LIST)))
            ? [{ path: "/approved-cases", label: "Approved Cases" }]
            : []
        ),
        ...((permission?.some(p => (p.name === userPermissions.CASES_LIST)))
            ? [{ path: "/archived-cases", label: "Archived Cases" }]
            : []
        ),
        { path: "/", label: "Divider" },
        ...((permission?.some(p => (p.name === userPermissions.CASES_STORE)))
            ? [{ path: "/upload-case", label: "Upload Case" }]
            : []
        ),
        ...(permission?.some((p) => p.name === userPermissions.CONTACT_REQUEST_LIST)
            ? [{ path: "/support-request", label: "Support Request" }]
            : []),
        ...((permission?.some(p => (p.name === userPermissions.PROFILE_UPDATE)))
            ? [{ path: "/profile", label: "Profile" }]
            : []
        ),
        { path: "/log-out", label: "Log Out", onClick: logout },
    ];

    return (
        <>
            {routes.map((route, index) => (
                <React.Fragment key={index}>
                    {route.onClick ? (
                        <li className={isRouteActive(route.path) ? "active" : ""}>
                            <a href="#" onClick={route.onClick}>{route.label}</a>
                        </li>
                    ) : route.label === 'Divider' ? (
                        <li className="navDivider"></li>
                    ) : route.label !== 'Divider' && (
                        <li className={isRouteActive(route.path) ? "active" : ""}>
                            <Link to={route.path}>{route.label}</Link>
                        </li>
                    )}
                </React.Fragment>
            ))}
        </>
    );
}

export default AssistantNav;